<script setup>
import { ref, defineExpose } from 'vue';

// Define props
const props = defineProps({
    id: {
        type: String,
        required: true,
    },
    title: {
        type: String,
        required: true,
        default: "Name",
    },
    type: {
        type: String,
        default: "text",
    },
    value: {
        type: String,
        default: "",
    },
    width: {
        type: String,
    },
    placeholder: {
        type: String,
        default: "",
    },
});

// Reactive value based on prop
const value = ref(props.value);

// Method to update the reactive value
const updateValue = (event) => {
    value.value = event.target.value;
};

// Expose getData method
defineExpose({
    getData: () => {
        return { value: value.value };
    }
});
</script>

<template>
    <div class="formComponentBody">
        <div class="formComponent_Title">{{ props.title }}</div>
        <div class="formInputFieldBody">
            <input :style="{ width: props.width }" class="formInput" :placeholder="props.placeholder" :value="value"
                :type="props.type" @input="updateValue" />
        </div>
    </div>
</template>
