import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import VueCropperJs from "vue-cropperjs";
// import "cropperjs/dist/cropper.css";
import { OhVueIcon, addIcons } from "oh-vue-icons";
import {
  FcFolder,
  RiDeleteBin5Line,
  IoCloseCircleOutline,
  FaInstagram,
  IoCaretDown,
  FaAngleDown,
  FaAngleUp,
  BiMeta,
  BiLinkedin,
  MdEmailRound,
  IoLocationSharp,
  HiSolidSearch,
  BiArrowRightShort,
  BiArrowRight,
  IoClose,
  LaExternalLinkSquareAltSolid,
  BiArrowRightCircleFill,
  LaFileDownloadSolid,
  BiTwitter,
  HiSolidLink,
  MdEmailOutlined,
  HiUser,
  IoCall,
  IoAttachOutline,
  PrShareAlt,
  BiBriefcase,
  BiBookmark,
  OiDotFill,
  GiHamburgerMenu,
  MdMorevert,
  HiSolidMenu,
  IoGridOutline,
  BiGrid,
  HiLocationMarker,
  HiRefresh,
  CoFilter,
  IoCloseOutline,
  FaSpinner,
  OiOrganization,
  LaMapSolid,
  RiEditBoxLine,
  BiCheckCircle,
  BiFileText,
  PrSpinner,
  FaEquals,
  MdTaskalt,
  OiTasklist,
  LaAngleDownSolid,
  BiCheckAll,
  BiHash,
  BiTelephone,
  MdAccesstime,
  BiCalendar3,
  MdContentcopyOutlined,
  MdContentcopyRound,
  IoCopyOutline,
  BiTrash3Fill,
  BiArrowUp,
  BiArrowDown,
  LaEditSolid,
  CoChartLine,
  BiEye,
  FaTrashAlt,
  BiZoomIn,
  BiZoomOut,
  PrDownload,
  BiCrop,
  HiLink,
  BiArrowsAngleExpand,
  BiArrowCounterclockwise,
  ViFileTypePdf2,
  ViDefaultFile,
  ViFileTypeZip,
  ViFileTypeText,
  IoDownloadOutline,
  MdKeyboardbackspace,
  IoAddOutline,
  BiPlus,
} from "oh-vue-icons/icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import ToastPlugin from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
addIcons(
  BiZoomIn,
  BiCheckCircle,
  RiEditBoxLine,
  FcFolder,
  RiDeleteBin5Line,
  IoCloseCircleOutline,
  FaInstagram,
  IoCaretDown,
  FaAngleDown,
  FaAngleUp,
  BiMeta,
  BiLinkedin,
  MdEmailRound,
  IoLocationSharp,
  HiSolidSearch,
  BiArrowRightShort,
  BiArrowRight,
  IoClose,
  BiBriefcase,
  LaExternalLinkSquareAltSolid,
  BiArrowRightCircleFill,
  LaFileDownloadSolid,
  BiTwitter,
  HiSolidLink,
  MdEmailOutlined,
  HiUser,
  IoCall,
  BiBookmark,
  PrShareAlt,
  IoAttachOutline,
  OiDotFill,
  GiHamburgerMenu,
  MdMorevert,
  HiSolidMenu,
  BiPlus,
  IoGridOutline,
  BiGrid,
  HiLocationMarker,
  HiRefresh,
  CoFilter,
  IoAddOutline,
  IoCloseOutline,
  FaSpinner,
  OiOrganization,
  LaMapSolid,
  BiFileText,
  PrSpinner,
  FaEquals,
  MdTaskalt,
  OiTasklist,
  LaAngleDownSolid,
  BiCheckAll,
  BiHash,
  BiTelephone,
  MdAccesstime,
  BiCalendar3,
  MdContentcopyOutlined,
  MdContentcopyRound,
  IoCopyOutline,
  BiTrash3Fill,
  BiArrowUp,
  BiArrowDown,
  LaEditSolid,
  CoChartLine,
  BiEye,
  FaTrashAlt,
  BiZoomOut,
  PrDownload,
  BiCrop,
  HiLink,
  BiArrowsAngleExpand,
  BiArrowCounterclockwise,
  ViFileTypePdf2,
  ViDefaultFile,
  ViFileTypeZip,
  ViFileTypeText,
  IoDownloadOutline,
  MdKeyboardbackspace
);
const app = createApp(App);

app.component("v-icon", OhVueIcon);
app.component("fa", FontAwesomeIcon);
app.use(ToastPlugin, {
  position: "op-rigtht",
  duration: 3000,
});
app.use(router);
app.use(store);
app.mount("#app");
// app.component(VueCropperJs);
