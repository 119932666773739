<script setup>
import { ref, defineExpose } from 'vue';

// Define props
const props = defineProps({
    id: {
        type: String,
        required: true,
    },
    title: {
        type: String,
        required: true,
        default: "Type",
    },
    name: {
        type: String,
        required: true,
        default: "type",
    },
    value: {
        type: String,
        required: true,
    },
    options: {
        type: Array,
        required: true,
        default: [],
    },
});

// Reactive value based on prop
const value = ref(props.value);

// Method to update the reactive value
const updateValue = (event) => {
    value.value = event.target.value;
};

// Expose getData method
defineExpose({
    getData: () => {
        return { value: value.value };
    }
});
</script>

<template>
    <div class="formComponentBody">
        <div class="formComponent_Title">{{ props.title }}</div>
        <div class="formRadioButtonGroup">
            <label v-for="option in props.options" :key="option.title"
                :class="{ formRadioButtonBody: true, active: value === option.title }">
                <input type="radio" :id="props.id" :name="props.name" :value="option.title"
                    :checked="value === option.title" class="radio-button-component" @input="updateValue" />
                <div class="textContainer">
                    <p class="headingText">{{ option.title }}</p>
                    <p class="descriptionText">{{ option.description }}</p>
                </div>
            </label>
        </div>
    </div>
</template>
