<script setup>
import FileIcon from "../../../public/temp.png";

const props = defineProps({
  img: String,
  name: String,
  id: String,
  date: String,
  click: Function,
  checkBoxClick: Function,
  selected: Boolean,
  deletePermission: Boolean
});

const handleClick = () => {
  props.click(props.id);
};

const handleCheckBoxClick = (event) => {
  event.stopPropagation();
  props.checkBoxClick(true, props.id, event.target.checked);
};

const getFilePreviewImageName = () => {
  const fileExtensions = {
    pdf: "vi-file-type-pdf2",
    txt: "vi-file-type-text",
    zip: "vi-file-type-zip",
    default: "vi-default-file",
  };

  const extension = props.name.split(".").pop().toLowerCase();

  return fileExtensions[extension] || fileExtensions.default;
};
</script>
<template>
  <div class="file-card">
    <div class="file-img" @click="handleClick">
      <div class="">
        <img
          v-if="/\.(jpg|jpeg|png)$/i.test(name)"
          class="file-preview"
          :src="img"
          alt=""
        />
        <v-icon class="file-preview"  v-else :name="getFilePreviewImageName()" scale="0.5" />
      </div>
      <input
        v-if="deletePermission"
        :checked="selected"
        type="checkbox"
        name=""
        id=""
        v-on:click="handleCheckBoxClick($event)"
      />
    </div>
    <div class="file-stat">
      <div class="file-name">
        <span class="" :title="name">{{ name.length > 20 ? name.substring(0, 60) + "..." : name}}</span>
      </div>
      <span class="file-date">{{
        new Date(date).toISOString().split("T")[0]
      }}</span>
    </div>
    <div v-if="selected" class="selected"></div>
  </div>
</template>
<style scoped>
.selected {
  background-color: rgba(0, 119, 255, 0.233) !important;
  height: 100%;
  position: absolute;
  width: 100%;
}
.file-card {
  position: relative;
  width: 222px;
  text-align: start;
  color: #606060;
  display: flex;
  flex-direction: column;
  justify-content: start;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.3rem;
  align-items: start;
  max-width: fit-content;
  gap: 10px;
  -webkit-box-shadow: 0px 2px 10px -4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 2px 10px -4px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 2px 10px -4px rgba(0, 0, 0, 0.2);
}
.file-preview {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.file-stat {
  padding-left: 0.5rem;
}
.file-date {
  font-size: small;
}
.file-stat > .file-name {
  white-space: nowrap;
  font-size: medium;
  font-weight: 500;
  text-overflow: ellipsis;
  width: 40%;
  overflow: hidden;
}
.file-img {
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}
.file-img > div {
  height: 200px;
}
input[type="checkbox"] {
  position: absolute;
  left: 0;
  top: 0;
  margin: 0.5rem;
  z-index: 1;
}
</style>
