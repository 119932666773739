const state = {
  showTechPopup: false,
  TechPopupObject: {},
};

const mutations = {
  setTechPopupObject(state, data) {
    state.TechPopupObject = data;
  },
  toggleTechPopup(state) {
    state.showTechPopup = !state.showTechPopup;
  },
};

const actions = {
  setTechPopupObject(context, data) {
    context.commit("setTechPopupObject", data);
  },
  toggleTechPopup(context) {
    context.commit("toggleTechPopup");
  },
};

const getters = {
  getTechPopupObject: (state) => state.TechPopupObject,
  getTechPopupVisibility: (state) => state.showTechPopup,
};
export default {
  state,
  mutations,
  actions,
  getters,
};
