import api_request from "./../../../libs/sendRequest/sendRequest";
import magicJson from "./../../../magicJson";

class jobsService {
  apiRequest = new api_request("/api/service");

  createJobs = async (data) => {
    try {
      magicJson.endpoint = "jobs";
      magicJson.executor = "createJobs";
      magicJson.data = [data];
      const result = await this.apiRequest.sendRequest(magicJson);
      return result;
    } catch (error) {
      return error;
    }
  };

  getJobs = async () => {
    try {
      magicJson.endpoint = "jobs";
      magicJson.executor = "getJobs";
      const result = await this.apiRequest.sendRequest(magicJson);
      return result.data;
    } catch (error) {
      return error;
    }
  };

  getEmailLeads = async () => {
    try {
      magicJson.endpoint = "jobs";
      magicJson.executor = "getEmailLeads";
      const result = await this.apiRequest.sendRequest(magicJson);
      return result.data;
    } catch (error) {
      return error;
    }
  };

  getJobsById = async (id) => {
    try {
      magicJson.endpoint = "jobs";
      magicJson.executor = "getJobsById";
      magicJson.data = [{ id }];
      const result = await this.apiRequest.sendRequest(magicJson);
      return result.data[0];
    } catch (error) {
      return error;
    }
  };

  getJobsByJobCode = async (jobCode) => {
    try {
      magicJson.endpoint = "jobs";
      magicJson.executor = "getJobsByJobCode";
      magicJson.data = [{ jobCode }];
      const result = await this.apiRequest.sendRequest(magicJson);
      return result.data[0];
    } catch (error) {
      return error;
    }
  };

  updateJobs = async (data, jobId) => {
    try {
      magicJson.endpoint = "jobs";
      magicJson.executor = "updateJobs";
      magicJson.data = [{ data, id: jobId }];
      const result = await this.apiRequest.sendRequest(magicJson);
      return result;
    } catch (error) {
      return error;
    }
  };

  updateMultipleJobs = async (data, jobIds) => {
    try {
      magicJson.endpoint = "jobs";
      magicJson.executor = "updateMultipleJobs";
      magicJson.data = [{ data, arr: jobIds }];
      const result = await this.apiRequest.sendRequest(magicJson);
      return result;
    } catch (error) {
      return error;
    }
  };

  deleteJobs = async (data) => {
    try {
      magicJson.endpoint = "jobs";
      magicJson.executor = "deleteJobs";
      magicJson.data = [data];
      const result = await this.apiRequest.sendRequest(magicJson);
      return result;
    } catch (error) {
      return error;
    }
  };
  jdGenerator = async (data) => {
    try {
      magicJson.endpoint = "jobs";
      magicJson.executor = "generateJD";
      magicJson.data = [data];
      const result = await this.apiRequest.sendRequest(magicJson);
      return result;
    } catch (error) {
      return error;
    }
  };
}

// export default new jobsService();
export default jobsService;
