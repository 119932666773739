import { defineComponent, ref, defineExpose } from "vue";
import "../../scss/_customComponents.scss";
import CustomInput from "@/CustomInput.vue";
export const Input = defineComponent({
  setup(props, { slots }) {
    return () => (
      <div class="input-component">
        {slots.default ? slots.default() : null}
      </div>
    );
  },
});

// Label Component
export const Label = defineComponent({
  props: {
    for: {
      type: String,
      required: false,
    },
  },
  setup(props, { slots }) {
    return () => (
      <label class="label-component" for={props.for}>
        {slots.default ? slots.default() : null}
      </label>
    );
  },
});

// InputField Component
export const InputField = defineComponent({
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
      default: "Name",
    },
    type: {
      type: String,
      default: "text",
    },
    modelValue: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "47.5%",
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const value = ref(props.modelValue);

    const updateValue = (event) => {
      value.value = event.target.value;
    };

    // Instead of defineExpose, directly return the method
    const getData = () => ({
      id: props.id,
      title: props.title,
      type: props.type,
      value: value.value,
      placeholder: props.placeholder,
    });

    return () => (
      <div class="formComponentBody">
        <div class="formComponent_Title">{props.title}</div>
        <div class="formInputFieldBody">
          <input
            style={{ width: props.width }}
            class="formInput"
            placeholder={props.placeholder}
            value={value.value}
            type={props.type}
            onInput={updateValue}
          />
        </div>
      </div>
    );
  },
});

// RadioButton Component
export const RadioButton = defineComponent({
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
      default: "Type",
    },
    name: {
      type: String,
      required: true,
      default: "type",
    },
    value: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
      default: [],
    },
  },
  setup(props) {
    const value = ref(props.value);

    const updateValue = (event) => {
      value.value = event.target.value;
    };

    // Instead of defineExpose, directly return the method
    const getData = () => ({
      id: props.id,
      title: props.title,
      value: value.value,
      options: props.options,
    });

    return () => (
      <div class="formComponentBody">
        <div class="formComponent_Title">{props.title}</div>
        <div class="formRadioButtonGroup">
          {props.options.map((option) => (
            <label
              class={{
                formRadioButtonBody: true,
                active: value.value === option.title,
              }}
            >
              <input
                type="radio"
                id={props.id}
                name={props.name}
                value={option.title}
                checked={value.value === option.title}
                class="radio-button-component"
                onInput={updateValue}
              />
              <div class="textContainer">
                <p class="headingText">{option.title}</p>
                <p class="descriptionText">{option.description}</p>
              </div>
            </label>
          ))}
        </div>
      </div>
    );
  },
});
// Checkbox Component
export const Checkbox = defineComponent({
  props: {
    id: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["update:value"],
  setup(props, { emit }) {
    const toggleCheckbox = (event) => {
      emit("update:value", event.target.checked);
    };

    return () => (
      <input
        type="checkbox"
        id={props.id}
        checked={props.value}
        class="checkbox-component"
        onChange={toggleCheckbox}
      />
    );
  },
}); // src/FormComponents.js
