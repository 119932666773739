import TextComponent from "../CustomComponents/TextComponent.vue";
import RadioComponent from "../CustomComponents/RadioComponent.vue";

export const formJSON = {
  textComponent: {
    basicSettings: [
      {
        style: "display: flex",
        components: [
          {
            component: TextComponent,
            id: "input1",
            type: "text",
            label: "Name",
            value: "",
            placeholder: "Enter value",
            options: [],
          },
          {
            component: TextComponent,
            id: "input1",
            type: "text",
            label: "Name",
            value: "",
            placeholder: "Enter value",
            options: [],
          },
        ],
      },
      {
        style: "display: flex",
        components: [
          {
            component: RadioComponent,
            id: "input2", // Ensure IDs are unique
            label: "Options",
            value: "",
            type: "radio",
            placeholder: "Enter value",
            options: [
              {
                title: "Short Text",
                description:
                  "Best for titles, names, link(URL). It also enables exact search on the field.",
              },
              {
                title: "Long Text",
                description:
                  "Best for descriptions, biographies. Exact search is disabled.",
              },
            ],
          },
        ],
      },
    ],
    advancedSettings: [
      {
        style: "display: flex",
        components: [
          {
            component: TextComponent,
            id: "input1",
            type: "text",
            label: "Name",
            value: "",
            placeholder: "Enter value",
            options: [],
          },
        ],
      },
      {
        style: "display: flex",
        components: [
          {
            component: RadioComponent,
            id: "input2", // Ensure IDs are unique
            label: "Options",
            value: "",
            type: "radio",
            placeholder: "Enter value",
            options: [
              {
                title: "Short Text",
                description:
                  "Best for titles, names, link(URL). It also enables exact search on the field.",
              },
              {
                title: "Long Text",
                description:
                  "Best for descriptions, biographies. Exact search is disabled.",
              },
            ],
          },
        ],
      },
    ],
  },
};
