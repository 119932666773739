import axios from "axios";
import { userToken } from "../../src/services/util";
// import cryptService from "../../src/services/CryptService/crypt.service";
class api_request {
  constructor(endpoint) {
    this.endpoint = process.env.VUE_APP_API;
  }
  async sendRequest(json, file = null, isSecure = true) {
    let data = new FormData();
    const token = userToken();
    data.append("request", JSON.stringify(json));
    data.append("isSecure", isSecure);

    if (file) {
      data.append("files", file);
    }
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: this.endpoint,
      headers: {
        // ...data.getHeaders(),
        Authorization: `Bearer ${token}`,
        "Content-type": "multipart/form-data",
      },
      data: data,
    };

    return axios
      .request(config)
      .then(async (response) => {
        const resp = response.data;
        if (resp.data[0].status === 403) {
          localStorage.removeItem("token");

          // Here we are using window.location because router.push not woking currently but in future we might need to change the code
          window.location.href = "/login";
          // this.$router.push("/login");
          return;
        } else {
          return resp;
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          localStorage.removeItem("token");
        }
        return error;
      });
  }
}

// module.exports = api_request;
export default api_request;
