import api_request from "./../../../libs/sendRequest/sendRequest";
import magicJson from "./../../../magicJson";

class assesmentFormService {
  apiRequest = new api_request("/api/service");

  getForms = async (data = {}) => {
    try {
      magicJson.endpoint = "assessmentForms";
      magicJson.executor = "getForms";
      magicJson.data = [data];
      const result = await this.apiRequest.sendRequest(magicJson, null, false);
      return result.data;
    } catch (error) {
      console.log(error);
    }
  };

  createForm = async (data) => {
    try {
      magicJson.endpoint = "assessmentForms";
      magicJson.executor = "createForms";
      magicJson.data = [data];
      const result = await this.apiRequest.sendRequest(magicJson, null, false);
      return result;
    } catch (error) {
      console.log(error);
    }
  };

  updateForm = async (data) => {
    try {
      magicJson.endpoint = "assessmentForms";
      magicJson.executor = "updateForms";
      magicJson.data = [data];
      const result = await this.apiRequest.sendRequest(magicJson, null, false);
      return result;
    } catch (error) {
      console.log(error);
    }
  };
  deleteForm = async (data) => {
    try {
      magicJson.endpoint = "assessmentForms";
      magicJson.executor = "deleteForm";
      magicJson.data = [data];
      const result = await this.apiRequest.sendRequest(magicJson, null, false);
      return result;
    } catch (error) {
      console.log(error);
    }
  };
}

export default assesmentFormService;
